<template>
  <div class="footer">
    <div class="footer-content mobile-footer">
      <div class="footer-grid">
        <div class="footer-left">
          <div class="footer-underline2"></div>
          <router-link to="/"><div
            ><img
              class="header-icon"
              src="@/assets/images/logo_schellenbauer-consult-2line_white.svg"
              alt="Schellenbauer Consult"
          /></div></router-link>
          <div class="copyright">
            &copy;2021 All Rights Reserved.
          </div>
        </div>
        <div class="footer-right">
          <div class="text"></div>
          <div id="item1" class="text"><a href="/#section1">What We Do</a></div>
          <div id="item2" class="text">
            <a href="/#section2"> Featured Work</a>
          </div>
          <div id="item3" class="text"><a href="/#section3">Who We Are</a></div>
          <div id="item4" class="text">
            <router-link to="/contact"><a>Contact</a></router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Footer",
};
</script>

<style scoped>
@media screen and (min-width: 1340px) {
  .footer {
    color: white;
    display: grid;
    height: 300px;
    background-color: #222222;
    align-items: center;
    justify-items: center;
  }

  .footer-content {
    padding-bottom: 2px;
    width: 90%;
    height: max-content;
    border-bottom: 1px solid #d9d9d9;
  }
  .footer-underline2 {
    display: none;
  }
  .footer-grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: max-content auto max-content;
    align-content: end;
    grid-auto-flow: column;
  }

  .footer-left {
    align-items: space-between;
    grid-template-rows: repeat(2, 40px);
  }

  .footer-left > .copyright {
    font-weight: bold;
  }
a > img {
    width: auto;
    height: 16px;
  }

  .footer-grid > div {
    width: auto;
    height: auto;
    display: grid;
    font-family: "Helvetica Medium";
    line-height: 30px;
    letter-spacing: 0.06px;
    font-size: 12px;
    margin-bottom: 32px;
  }
  .text {
    font-size: 14px;
    text-align: right;
    grid-row: 2;
    font-weight: bold;
    color: white;
    line-height: 25px;
    letter-spacing: -0.14px;
    text-transform: capitalize;
    align-self: flex-end;
    width: max-content;
  }
  .text a {
    color: white;
  }
  .footer-right {
    grid-column: 3;
    grid-column-gap: 60px;
    justify-items: end;
  }
  .footer-a a {
    text-decoration: none;
  }
  .footer-left {
    align-items: space-between;
    grid-template-rows: repeat(2, 40px);
  }
  .footer-grid > .mobile-footer-underline {
    display: none;
  }
  .footer-grid > .mobile-footer-underline2 {
    display: none;
  }
  .copyright {
    padding-top: 10px;
  }
}
/*tablet css*/
@media screen and (min-width: 750px) and (max-width: 1024px) {
  .footer {
    color: white;
    display: grid;
    height: 360px;
    background-color: #222222;
    justify-items: center;
  }
  .footer-content {
    width: 100%;
    /* height: 100%; */
  }

  .footer-grid {
    display: grid;
    margin: 0px 20px 0px 20px;
    border-bottom: 1px solid white;
    grid-template-rows: 220px auto;
  }
  .text {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: -0.14px;
    grid-column: 1;
    height: max-content;
  }
  .text a {
    color: white;
  }

  .footer-right {
    grid-row: 1;
    display: grid;
  }

  .footer-left {
    grid-row: 2;
    display: grid;
  }
  .footer-left > div > .header-icon {
    height: 16px;
    width: auto;
  }

  .footer-left > a {
    margin-top: 20px;
    height: max-content;
  }
  .footer-left p {
    font-weight: bold;
    padding-top: 10px;
  }

  .footer-underline2 {
    border-bottom: 1px solid#d9d9d9;
  }
  .mobile-footer-underline2 {
    margin-bottom: 50px;
    margin-top: 20px;
    border-bottom: 1px solid #d9d9d9;
  }
.header-icon {
    height: 16px;
    width: auto;
  }
  .copyright {
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: 0.06px;
    height: max-content;
    margin-bottom: 20px;
  }
}
/* mobile css*/
@media screen and (max-width: 750px) {
  .footer {
    color: white;
    display: grid;
    height: 360px;
    background-color: #222222;
    justify-items: center;
  }
  .footer-content {
    width: 100%;
    /* height: 100%; */
  }

  .footer-grid {
    display: grid;
    margin: 0px 20px 0px 20px;
    border-bottom: 1px solid white;
    grid-template-rows: 220px auto;
  }
  .text {
    font-size: 14px;
    text-align: center;
    font-weight: bold;
    line-height: 25px;
    letter-spacing: -0.14px;
    grid-column: 1;
    height: max-content;
  }
  .text a {
    color: white;
  }
  .footer-right {
    grid-row: 1;
    display: grid;
    border-bottom: 1px solid#d9d9d9;
  }

  .footer-left {
    grid-row: 2;
    display: grid;
    place-items: center;
  }
.header-icon {
    height: 16px;
    width: auto;
  }

  .footer-left > a {
    margin-top: 20px;
    height: max-content;
  }
  .footer-left p {
    font-weight: bold;
    padding-top: 10px;
  }

  .footer-underline2 {
    border-bottom: 1px solid#d9d9d9;
  }
  .mobile-footer-underline2 {
    margin-bottom: 50px;
    margin-top: 20px;
    border-bottom: 1px solid #d9d9d9;
  }

  .copyright {
    font-size: 12px;
    margin-top: 10px;
    letter-spacing: 0.06px;
    height: max-content;
    margin-bottom: 20px;
  }
}
@media screen and (min-width: 1024px) {
  .footer {
    color: white;
    display: grid;
    height: 300px;
    background-color: #222222;
    align-items: center;
    justify-items: center;
  }

  .footer-content {
    padding-bottom: 2px;
    width: 90%;
    height: max-content;
    border-bottom: 1px solid #d9d9d9;
  }
  .footer-underline2 {
    display: none;
  }
  .footer-grid {
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-rows: repeat(1, auto);
    grid-template-columns: max-content auto max-content;
    align-content: end;
    grid-auto-flow: column;
  }

  .footer-left {
    align-items: space-between;
    grid-template-rows: repeat(2, 40px);
  }

  .footer-left > .copyright {
    font-weight: bold;
  }
a > img {
    width: auto;
    height: 16px;
  }

  .footer-grid > div {
    width: auto;
    height: auto;
    display: grid;
    font-family: "Helvetica Medium";
    line-height: 30px;
    letter-spacing: 0.06px;
    font-size: 12px;
    margin-bottom: 32px;
  }
  .text {
    font-size: 14px;
    text-align: right;
    grid-row: 2;
    font-weight: bold;
    color: white;
    line-height: 25px;
    letter-spacing: -0.14px;
    text-transform: capitalize;
    align-self: flex-end;
    width: max-content;
  }
  .text a {
    color: white;
  }
  .footer-right {
    grid-column: 3;
    grid-column-gap: 60px;
    justify-items: end;
  }
  .footer-a a {
    text-decoration: none;
  }
  .footer-left {
    align-items: space-between;
    grid-template-rows: repeat(2, 40px);
  }
  .footer-grid > .mobile-footer-underline {
    display: none;
  }
  .footer-grid > .mobile-footer-underline2 {
    display: none;
  }
.header-icon {
    height: 16px;
    width: auto;
  }
  .copyright {
    padding-top: 10px;
  }
}
</style>
