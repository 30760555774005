<template>
<div>
    <!-- Displayed when screen is larger than 750px -->
<div class="header header-visibility header-grid-props header-styling">
    <div class="header-left standard-left-padding header-visibility header-grid-props">
      <div class="header-grid-item center-horizontal">
        
        <router-link to="/">
        <div class="header-icon-container">
            <img
            class="header-icon"
            :src="require('@/assets/images/logo_S&CO.svg')"
            >
            <img>
            </div>
        </router-link>
      </div>
    </div>
    <div class="header-right standard-right-padding header-visibility header-grid-props">
      <div class="header-grid-item center-horizontal" id="dropdown-trigger">
        <a href="/#section1">What We Do</a>
        <div class="dropdown-content header-styling">
          <div><router-link to="/webdevelopment"><a>Web Development</a></router-link></div>
          <div><router-link to="/business"><a>Business Intelligence & Data Science</a></router-link></div>
          <div><router-link to="/marketing"><a>Marketing Strategy</a></router-link></div>
          <div><router-link to="/paid"><a>Tailormade applications & systems</a></router-link></div>
        </div>
      </div>
      <div class="header-grid-item center-horizontal">
        <a href="/#section2">Featured Work</a>
      </div>
      <div class="header-grid-item center-horizontal">
        <a href="/#section3">Who We Are</a>
      </div>
      <div class="header-grid-item center-horizontal">
        <router-link to="/contact"><button class="btn white btn-header">Start A Project</button></router-link>
      </div>
    </div>
  </div>
  <!-- Displayed when screen is less than 750px -->
  <div id="mobile-header">
    <a href="javascript:void(0);" class="menubars" @click="showNav()">
      <i class="fa fa-bars" id="menu"></i>
      <i class="fa fa-close" id="close"></i>
    </a>
    <div class="header-item nav1 logo">
      <router-link to="/"><a><img class="header-icon" src="@/assets/images/logo_schellenbauer-consult-2line_white.svg"
          alt="Schellenbauer Consult" /></a>
      </router-link>
    </div>
    <div id="mobile-nav">
      <div class="header-item nav2 dropdown-trigger">
        <div class="h20pad">
          <a href="#section1" @click="showNav()">What We Do</a>
        </div>
        <div class="dropdown-content">
          <div><a  href="/webdevelopment" @click="showNav()">Web Development</a></div>
          <div><a  href="/business" @click="showNav()">Business Intelligence & Data Science</a></div>
          <div><a  href="/marketing" @click="showNav()">Marketing Strategy</a></div>
          <div><a  href="/paid" @click="showNav()">Tailormade Applications & Systems</a></div>
          <!-- <div><router-link to="/business" @click="showNav()"><a>Business Intelligence & Data Science</a></router-link></div>
          <div><router-link to="/marketing" @click="showNav()"><a>Marketing Strategy</a></router-link></div>
          <div><router-link to="/paid" @click="showNav()"><a>Tailormade Applications & Systems</a></router-link></div> -->
        </div>
      </div>
      <div class="nav3 header-item">
        <div class="h20pad"><a href="#section2" @click="showNav()">Featured Work</a></div>
      </div>
      <div class="nav4 header-item">
        <div class="h20pad "><a href="#section3" @click="showNav()">Who We Are</a></div>
      </div>
      <div class="nav5">
        <div class="h10pad header-contact-btn">
          <router-link to="/contact" @click="showNav()"><button><a>Start A Project</a></button></router-link>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
export default {
    name: "Header",
    methods:{
        showNav() {
    console.log('Home');
    var x = document.getElementById("mobile-nav");
    var menu = document.getElementById("menu");
    var close = document.getElementById("close");
    if (x.style.display == "block") {
        x.style.display = "none";
        menu.style.display = "block";
        close.style.display = "none";
    } else {
        x.style.display = "block";
        menu.style.display = "none";
        close.style.display = "block";
    }
}
    }
}
</script>

<style scoped>
@media screen and (min-width: 750px) and (max-width: 1024px){
  /* dropdown stuff */

  .header-visibility {
    display: none;
  }
  #mobile-header {
    -moz-box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;
    position: fixed;
    width: 100%;
    background-color: #222222;
  }
  #mobile-nav {
    display: none;
    background-color: #222222;
    justify-content: left;
    height: auto;
    z-index: 1;
    position: relative;
    width: 100%;
  }

  .nav5 {
    height: 60px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .nav5 button {
    background-color: white;
  }
  #mobile-header .nav5 a {
    color: #222222;
    text-decoration: none;
  }

  #close {
    display: none;
  }

  .dropdown-content {
    display: none;
    /* width: 100%; */
    /* position: relative; */
    background-color: #222222;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    /* top: 90px; */
  }
  .h20pad {
    display: grid;
    align-content: center;
    justify-content: center;
    /* width: 100%;
    height: 100%; */
  }

  .h30pad {
    padding-top: 40px;
    text-align: center;
  }
  .dropdown-content > div {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 45px;
  }
  .dropdown-content a {
    text-decoration: none;
    color: white;
  }
  
  .dropdown-trigger> .h20pad > a::after{
    content: ' +';
  }

  .dropdown-trigger:hover > .dropdown-content {
    border-top: 1px white solid;
    border-bottom: 1px white solid;
    display: block;
  }
  .dropdown-trigger:hover > .h20pad {
    height: 45px;
  }

  .dropdown-content + div:hover {
    background-color: #222222;
  }
  /* header controls */

  .hide-navigation-item {
    display: none !important;
  }
  #mobile-header .header-item a {
    text-decoration: none;
    color: white;
  }

  .header-item {
    min-height: 40px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .nav1 {
    grid-area: nav1;
  }
  /* .nav2 {
    grid-area: nav2;
  } */
  .nav3 {
    grid-area: nav3;
  }
  .nav4 {
    grid-area: nav4;
  }
  .nav5 {
    grid-area: nav5;
  }
  #mobile-header .menubars,
  .close {
    float: right;
    padding: 18px;
    font-size: 24px;
  }

  /* Text styling */
  #mobile-header i {
    color: white;
    width: 27px;
    text-align: center;
  }
  .logo {
    width: max-content;
    height: 64px;
    padding-left: 20px;
    display: grid;
    align-items: center;
  }

  .header-icon {
    width: 200px;
    height: auto;
    padding-top: 2px;
  }

}


@media screen and (min-width: 1024px) {
  .menubars {
    display: none;
  }
  /* CONSTANTS */
  .standard-left-padding {
    padding-left: 100px;
  }
  .standard-right-padding {
    padding-right: 100px;
  }

  .header-right * {
    font-size: 15px;
    letter-spacing: 0.8px;
    color: white;
  }

  .center-horizontal {
    align-self: center;
  }

  .btn.white.btn-header {
    width: 204px;
    height: 44px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 50px;
    font-family: "Helvetica bold";
    font-size: medium;
  }
  
  .white {
    background-color: white;
    color: #222222;
  }

  .header {
    z-index: 1;
    position: fixed;
    width: 100%;
    height: 90px;
  }

  .header-styling{
    background-color: #222;
    -moz-box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
  }

  .header-visibility {
    display: grid;
  }

  .header-right {
    justify-content: end;
    grid-auto-columns: max-content;
    grid-column-gap: 38px;
  }

  .header-grid-props {
    align-content: center;
    grid-auto-flow: column;
  }

  .header-grid-item {
    height: max-content;
    width: max-content;
  }

  .header a {
    display: inline-block;
    height: max-content;
    width: max-content;
  }

  .header-icon {
    width: 250px;
    height: auto;
  }
  .header-icon-container{
    display: grid;
    place-items: center;
  }

  .dropdown-content {
    display: none;
    position: absolute;
    top: 83px;
  }
  #dropdown-trigger:hover > .dropdown-content {
    display: grid;
  }

  .dropdown-content > div {
    padding-left: 30px;
    padding-right: 30px;
    display: grid;
    align-content: center;
    justify-content: left;
    height: 60px;
  }

  #dropdown-trigger {
    align-content: center;
    height: 83px;
  }

  #dropdown-trigger > a {
    padding: 33px;
    padding-right: 0px;
  }
}

@media screen and (max-width: 750px) {
  /* dropdown stuff */
  .header-visibility {
    display: none;
  }
  #mobile-header {
    -moz-box-shadow: 0px 3px 6px #00000029;
    -webkit-box-shadow: 0px 3px 6px #00000029;
    box-shadow: 0px 3px 6px #00000029;
    z-index: 1;
    position: fixed;
    width: 100%;
    background-color: #222222;
  }
  #mobile-nav {
    display: none;
    background-color: #222222;
    justify-content: left;
    height: auto;
    z-index: 1;
    position: relative;
    width: 100%;
  }

  .nav5 {
    height: 60px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .nav5 button {
    background-color: white;
  }
  #mobile-header .nav5 a {
    color: #222222;
    text-decoration: none;
  }

  #close {
    display: none;
  }

  .dropdown-content {
    display: none;
    /* width: 100%; */
    /* position: relative; */
    background-color: #222222;
    /* box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2); */
    z-index: 1;
    /* top: 90px; */
  }
  .h20pad {
    display: grid;
    align-content: center;
    justify-content: center;
    /* width: 100%;
      height: 100%; */
  }

  .h30pad {
    padding-top: 40px;
    text-align: center;
  }
  .dropdown-content > div {
    display: grid;
    justify-items: center;
    align-items: center;
    height: 45px;
  }
  .dropdown-content a {
    text-decoration: none;
    color: white;
  }

  .dropdown-trigger > .h20pad > a::after {
    content: " +";
  }

  .dropdown-trigger:hover > .dropdown-content {
    border-top: 1px white solid;
    border-bottom: 1px white solid;
    display: block;
  }
  .dropdown-trigger:hover > .h20pad {
    height: 45px;
  }

  .dropdown-content + div:hover {
    background-color: #222222;
  }
  /* header controls */

  .hide-navigation-item {
    display: none !important;
  }
  #mobile-header .header-item a {
    text-decoration: none;
    color: white;
  }

  .header-item {
    min-height: 40px;
    display: grid;
    align-items: center;
    justify-content: center;
  }
  .nav1 {
    grid-area: nav1;
  }
  /* .nav2 {
      grid-area: nav2;
    } */
  .nav3 {
    grid-area: nav3;
  }
  .nav4 {
    grid-area: nav4;
  }
  .nav5 {
    grid-area: nav5;
  }
  #mobile-header .menubars,
  .close {
    float: right;
    padding: 18px;
    font-size: 24px;
  }

  /* Text styling */
  #mobile-header i {
    color: white;
    width: 27px;
    text-align: center;
  }
  .logo {
    width: max-content;
    height: 64px;
    padding-left: 20px;
    display: grid;
    align-items: center;
  }

  .header-icon {
    width: 200px;
    height: auto;
    padding-top: 2px;
  }

}


  
</style>