<template>
  <div id="app">
    <Header />
    <router-view />
    <Footer />
  </div>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
export default {
  components: {
    Header,
    Footer,
  },
};
</script>

<style>
/* You can add global styles to this file, and also import other style files */

@font-face {
  font-family: "Helvetica Medium";
  src: url("src/../assets/fonts/HelveticaNeue-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Light";
  src: url("src/../assets/fonts/HelveticaNeue-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Black";
  src: url("src/../assets/fonts/HelveticaNeue-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Bold";
  src: url("src/../assets/fonts/HelveticaNeue-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

* {
  margin: 0;
  font-family: "Helvetica";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

.textColorWhite p {
  color: #ffffff;
  opacity: 0.8;
}
.hrColorWhite hr {
  border: 3px solid #ffffff;
  opacity: 0.8;
}
.hrDefaultColor {
  border: 3px solid #222222;
  opacity: 1;
}
.textColorBlack p {
  color: #727272;
  opacity: 1;
}
.labelColorGrey {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
}
.labeColorWhite {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
}

button {
  height: 35px;
  width: 180px;
  color: white;
  font-weight: bold;
  background-color: #222222;
  border-radius: 20px;
  border-color: transparent;
}

.main-banner {
  position: relative;
  text-align: center;
  color: white;
}

.main-banner img {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: auto;
}

.content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

img {
  width: 100%;
  position: relative;
}

.container {
  position: relative;
  text-align: center;
  color: white;
  height: 100%;
}
.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, -50%);
}

.centered h1 {
  font-size: 50px;
}
.centered p {
  padding-left: 170px;
  padding-right: 170px;
  padding-top: 16px;
  padding-bottom: 30px;
}
.centered_img {
  position: absolute;
  bottom: 0;
  top: 100%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.centered_img img {
  filter: grayscale();
}
.secondbanner {
  height: 100%;
  background: #f8f8f8 0% 0% no-repeat padding-box;
  opacity: 1;
}

.grid > h2 {
  font: Medium 35px/32px Helvetica Neue;
  letter-spacing: 0px;
  color: #222222;
  text-transform: capitalize;
  opacity: 1;
}
.grid > p {
  font: Light 20px/30px Helvetica Neue;
  letter-spacing: 1px;
  color: #000000;
  opacity: 1;
}
.icon {
  position: relative;
}
.icon > img {
  width: 78px;
  height: 78px;
}
.FirstGrid {
  margin-left: 0;
  float: left;
}

.defaultcolor {
  background: #222222 0% 0% no-repeat padding-box;
}

.FourthBannerContent {
  height: auto;
  text-align: center;

  padding: 10% 22% 0;
  /* background: #222222 0% 0% no-repeat padding-box; */
  height: 60%;
  text-align: center;
}
.FourthBannerContent > h3 {
  /* text-align: center; */
  letter-spacing: 0.75px;
  color: #000000;
  opacity: 1;
  text-align: left;
  font: Light 15px/20px Helvetica Neue;
}

.business-background {
  background: rgba(0, 0, 0, 0.5)
    url("./assets/images/Banner_businessintelligence2.jpg") 0% 0% no-repeat
    padding-box;
  background-size: cover;
  background-position: left;
  background-blend-mode: darken;
}
.web-background {
  background: rgba(0, 0, 0, 0.5)
    url("./assets/images/Banner_webdevelopment2.jpg") 0% 0% no-repeat
    padding-box;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
}
.marketing-background {
  background: rgba(0, 0, 0, 0.5)
    url("./assets/images/Banner_marketingstrategy2.jpg") 0% 0% no-repeat
    padding-box;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
}
.paidmedia-background {
  background: rgba(0, 0, 0, 0.5) url("./assets/images/Banner_paidmedia2.jpg") 0%
    0% no-repeat padding-box;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
}
.home-background {
  background: rgba(0, 0, 0, 0) url("./assets/images/bannerimage.png") 0% 0%
    no-repeat padding-box;
  background-size: cover;
  background-position: center;
  background-blend-mode: darken;
}
.hrdiv {
  width: 38px;
  height: 3px;
  margin-top: 8px;
  opacity: 1;
}
.hrdiv-default-color {
  background-color: #f8f8f8;
}
.hrdiv-grey-color {
  /*bars*/
  background-color: #3e3e3e;
}

.pdiv {
  padding-left: 12px;
  font-family: "Helvetica Light";
  color: #727272;
  font-size: 16px;
}

.pdiv p {
  color: #727272;
}

.bold-link {
  font-family: "Helvetica Bold";
  color: #222;
}

/* On smaller screens, decrease text size */
@media only screen and (max-width: 300px) {
  .prev,
  .next,
  .text {
    font-size: 11px;
  }
}
</style>
