<template>
  <div class="featured-work">
    <div class="label labelColorGrey textColorBlack" id="section2">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Featured Work</p>
      </div>
    </div>

    <!-- //regular display -->

    <div class="FifthBannerContent labelColorGrey">
      <div>
        <div class="fifthgrid">
          <h2>Gasoline Grill</h2>
          <img src="@/assets/images/GG.jpg" alt="" />
          <p>
            Development and implementation of website, with integrated food
            delivery platform.
          </p>
          <span
            ><a href="https://www.gasolinegrill.com/"
              >Go to website &#187;</a
            ></span
          >
        </div>

        <div class="fifthgrid">
          <h2>Travelstart</h2>
          <img src="@/assets/images/TRAVEL.jpg" alt="" />
          <p>
            Integration of multilayer business intelligence across several
            platforms.
          </p>
          <span
            ><a href="https://www.travelstart.com/"
              >Go to website &#187;</a
            ></span
          >
        </div>

        <div class="fifthgrid">
          <h2>Prepared</h2>
          <img src="@/assets/images/PREPARED.jpg" alt="" />
          <p>
            Creation of brand. Development and implementation of website.
            Integration with ERP system.
          </p>
          <span
            ><a href="https://preparedblanks.com/"
              >Go to website &#187;</a
            ></span
          >
        </div>

        <div class="fifthgrid">
          <h2>A&M</h2>
          <img src="@/assets/images/A_M.jpg" alt="" />
          <p>
            Development and implementation of website, with a B2C and B2B facing
            shop.
          </p>
          <span
            ><a href="https://www.andersenmaillard.dk/"
              >Go to website &#187;</a
            ></span
          >
        </div>
      </div>
    </div>

    <!-- //slideshow -->

    <div class="featured-work-slideshow-container secondarycolor">
      <carousel
        :autoplay="true"
        :perPage="1"
        :autoplayHoverPause="true"
        :loop="true"
        :autoplayTimeout="5000"
      >
        <slide>
          <div class="mySlides fade">
            <h2>Gasoline Grill</h2>
            <img src="@/assets/images/GG@2x.jpg" alt="" />
            <p>
              Development and implementation of website, with integrated food
              delivery platform. Marketing strategy.
            </p>
            <span
              ><a href="https://www.gasolinegrill.com/"
                >Go to website &#187;</a
              ></span
            >
          </div>
        </slide>
        <slide>
          <div class="mySlides fade">
            <h2>Travelstart</h2>
            <img src="@/assets/images/TRAVEL@2x.jpg" />
            <p>
              Multilayer business intelligence integrations across several
              platforms.Marketing strategy.
            </p>
            <span
              ><a href="https://www.travelstart.com/"
                >Go to website &#187;</a
              ></span
            >
          </div>
        </slide>
        <slide>
          <div class="mySlides fade">
            <h2>Prepared</h2>
            <img src="@/assets/images/PREPARED@2x.jpg" alt="" />
            <p>
              Creation of brand. Development and implementation of website.
              Integration with ERP system. Marketing strategy.
            </p>
            <span
              ><a href="https://preparedblanks.com/"
                >Go to website &#187;</a
              ></span
            >
          </div>
        </slide>
        <slide>
          <div class="mySlides fade">
            <h2>A&M</h2>
            <img src="@/assets/images/A_M@2x.jpg" alt="" />
            <p>
              Development and implementation of website, with integrated food
              delivery platform. Marketing strategy.
            </p>
            <span
              ><a href="https://www.andersenmaillard.dk/"
                >Go to website &#187;</a
              ></span
            >
          </div>
        </slide>
      </carousel>
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: { Carousel, Slide },
};
</script>
<style>
@media screen and (max-width: 749px) {
  .FifthBannerContent {
    display: none;
  }

  .featured-work-slideshow-container {
    background: #f8f8f8;
    position: relative;
    margin: auto;
    padding: 0 20px 30px 20px;
  }

  .mySlides {
    padding: 20px 20px 10px 20px;
    display: block;
  }
  .mySlides h2 {
    padding-bottom: 8px;
    font-size: 10vw;
  }
  .mySlides img {
    padding-bottom: 10px;
  }
  .mySlides p {
    padding-bottom: 10px;
    /* height: 90px; */
  }
  .mySlides a {
    font-weight: bold;
    text-decoration: none;
    color: #222222;
  }
}
  
@media screen and (min-width: 749px) {
  .featured-work-slideshow-container{
    display: none;
  }
}
</style>
