<template>
  <div>
    <!-- MAIN BANNER -->
    <div class="fb-style home-background fb-grid firstSectionPadFix">
      <div class="fb-content">
        <h1>Your Data Consultant</h1>
        <h3>
          Helping you analyze, strategize and boost your product offerings
          digitally.
        </h3>
        <router-link to="/contact"
          ><a
            ><button class="fb-content-btn">Contact Us</button></a
          ></router-link
        >
      </div>
    </div>
    <!-- END BANNER -->
    <MainWhatWeDo />
    <FeaturedWork />
    <WhoWeAre />
    <Testimonials />
  </div>
</template>

<script>
import MainWhatWeDo from "../components/MainWhatWeDo.vue";
import Testimonials from "../components/Testimonials.vue";
import FeaturedWork from "../components/FeaturedWork.vue";
import WhoWeAre from "../components/WhoWeAre.vue";
export default {
  components: { MainWhatWeDo, WhoWeAre, Testimonials, FeaturedWork },
  name: "Homepage",
  title: "Schellenbauer & Co. - Home",
};
</script>

<style scoped>
@import '../assets/css/web.css';
@import '../assets/css/tablet.css';
@import '../assets/css/mobile.css';
@media screen and (min-width: 1024px) {
  .fb-content {
    grid-row: 2 / span 4;
    display: grid;
    height: 50%;
    justify-items: center;
    align-self: center;
  }
}
</style>
