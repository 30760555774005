<template>
  <div class="testimonials">
    <div class="label textColorBlack labelColorGrey">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Testimonials</p>
      </div>
    </div>
    <!-- https://ssense.github.io/vue-carousel/api/ -->
    <div class="WebFourthBannerContent labelColorGrey">
      <div class="carousel">
        <carousel
          :autoplay="true"
          :perPage="1"
          :autoplayHoverPause="true"
          :loop="true"
          :autoplayTimeout="5000"
        >
          <slide class="slide">
            <div class="slide-container">
              <div class="slide-content-container">
                <div class="fourthbanner-slides">
                  <h3>"Solid performance!"</h3>
                  <div>
                    Schellenbauer & Co conceptualized, designed and implemented
                    our website including an end-to-end online pickup and
                    delivery service. It has been a pleasure working with the
                    team, and we continue to do so.
                  </div>
                  <p>- Klaus Wittrup, Owner, Gasoline Grill</p>
                </div>
              </div>
            </div>
          </slide>

          <slide>
            <div class="slide-container">
              <div class="slide-content-container">
                <div class="fourthbanner-slides">
                  <h3>"Fantastic team!"</h3>
                  <div>
                    Having worked with Carsten and the team for more than three
                    years now, we can highly recommend Schellenbauer & Co.
                  </div>
                  <p>- Benjamin Greene, Director of Sales, Prepared Blanks</p>
                </div>
              </div>
            </div>
          </slide>
        </carousel>
      </div>

      <!-- <div style="text-align: center" class="spanDots">
        <span class="dots" onclick="currentSlides(1)"></span>
        <span class="dots" onclick="currentSlides(2)"></span>
      </div> -->
    </div>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";
export default {
  components: { Carousel, Slide },
};
</script>

<style scoped>
@media screen and (min-width: 1024px) and (min-width: 1340px) {
  .slide-content-container {
    padding: 30px 20px 0 20px;
    display: flex;
    place-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .slide-content-container {
    padding: 30px 20px 0 20px;
    display: grid;
    place-items: center;
  }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
  .slide-container {
    display: grid;
    place-items: center;
  }
  .slide-content-container {
    padding: 30px 20px 0 20px;
    display: grid;
    place-items: center;
    max-width: 75%;
  }
}

@media screen and (max-width: 750px) {
  .slide-content-container {
    padding-top: 30px;
    display: grid;
    place-items: center;
  }
}
</style>
