import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/business",
    name: "Business",
    component: () =>
      import("../components/Business.vue")
  },
  {
    path: "/contact",
    name: "Contact",
    component: () =>
      import("../components/Contact.vue")
  },
  {
  path: "/marketing",
  name: "Marketing",
  component: () =>
    import("../components/Marketing.vue")
  },
  {
  path: "/webdevelopment",
  name: "Webdevelopment",
  component: () =>
    import("../components/Webdevelopment.vue")
  },
  {
    path: "/paid",
    name: "Paid",
    component: () =>
      import("../components/Paid.vue")
    }

];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior() {
    window.scrollTo(0,0);
  }
  
});

export default router;
