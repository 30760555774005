<template>
  <div class="who-we-are">
    <div class="label defaultcolor2 textColorWhite" id="section3">
      <div class="hrdiv hrdiv-grey-color"></div>
      <div class="pdiv">
        <p>&nbsp;Who We Are</p>
      </div>
    </div>
    <div class="ThirdBanner defaultcolor2">
      <div class="ThirdBannerContent webthirdbanner">
        <h3>
          We are a team of marketers, software developers, software engineers
          and graphic designers from Denmark, Sweden, and South Africa. We have a well-founded position to assist our clients
          with tasks within the digital space at competitive prices.
        </h3>
        <router-link to="/contact"
          ><a><button class="blk-btn">Call Us Today</button></a></router-link
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
@media screen and (min-width: 1024px) {
  .ThirdBanner {
    display: grid;
    padding: 30px 30px 60px 30px;
  }
  .ThirdBannerContent {
    place-self: center;
    width: 70vw;
    height: max-content;
    display: grid;
    justify-items: center;
  }

  .ThirdBannerContent h3 {
    max-width: 1070px;
    font-family: "Helvetica Medium";
    line-height: 50px;
    font-size: 35px;
    letter-spacing: 0.7px;
    text-align: center;
    color: #222;
  }

  button {
    color: white;
    font-size: 16px;
    text-align: center;
    border-radius: 50px;
    background-color: #222222;
    margin-top: 25px;
    font-family: "Helvetica Bold";
    height: 44px;
    width: 225px;
    margin-bottom: 50px;
  }

  .ThirdBannerContent > button > a {
    font-family: "Helvetica Medium";
    color: #000;
    font-size: 15px;
    text-transform: capitalize;
  }
}

@media screen and (min-width: 750px) and (max-width: 1024px) {
  .ThirdBanner {
    display: grid;
    padding: 30px 30px 60px 30px;
  }

  .ThirdBannerContent {
    place-self: center;
    width: 70vw;
    height: max-content;
    display: grid;
    justify-items: center;
  }

  .ThirdBannerContent h3 {
    font-family: "Helvetica Medium";
    line-height: 40px;
    font-size: 30px;
    letter-spacing: 0.7px;
    color: #000;
    text-align: center;
  }

  a > button {
    margin-top: 30px;
    height: 61px;
    width: max-content;
    padding-left: 50px;
    padding-right: 50px;
    border-radius: 50px;
    background-color: #000;
  }

  a > button {
    font-family: "Helvetica Medium";
    color: #fff;
    font-size: 18px;
    text-transform: capitalize;
  }

  .defaultcolor2 {
    background: #ffffff 0% 0% no-repeat padding-box;
  }
}

@media screen and (max-width: 750px) {
  .ThirdBannerContent {
    display: grid;
    grid-template-rows: auto 50px;
    padding: 0px 25px 50px 25px;
    min-height: 400px;
    text-align: center;
    justify-items: center;
  }
  .ThirdBannerContent a {
    text-decoration: none;
    color: #222222;
    font-weight: bold;
  }
  .ThirdBannerContent h3 {
    font-family: "Helvetica Medium";
    font-size: 20px;
    line-height: 32px;
    height: max-content;
    line-height: 1.5;
    font-weight: normal;
    color: #000;
    place-self: center;
  }
  .ThirdBannerContent button {
    max-width: 175px;
    height: 51px;
    border-radius: 50px;
    font-size: 16px;
    margin-bottom: 30px;
    color: white;
    font-weight: bold;
    background-color: #000;
    border-color: transparent;
  }
}
</style>
